.app {
  .sidebar {
    position: fixed;
    top: 0;
    left: 0;
    width: var(--sidebar-width);
    height: 100%;
    background: var(--sidebar-background);
    overflow-y: auto;
    display: flex;
    flex-direction: column;

    .sidebar-body {
      flex-grow: 1;
    }

    .sidebar-footer {
      padding: 1rem;

      .sidebar-footer-body {
        color: var(--sidebar-color-default);
        display: block;
        border-radius: 10px;
        padding: 0.5rem 0.8rem;
        background: var(--sidebar-footer-background);
        font-size: 0.7rem;

        a {
          color: var(--sidebar-color-default)
        }
      }
    }

    .logo {
      display: block;
      text-align: left;
      text-decoration: none;
      color: var(--sidebar-logo-color);
      font-family: var(--sidebar-logo-font);
      font-size: var(--sidebar-logo-font-size);
      padding: 0.2rem 0;
      line-height: 2.9rem;
      font-weight: 700;
      border-bottom: 2px solid var(--sidebar-logo-color);

      margin: 0.5rem 2rem;

      img {
        display: inline-block;
        margin: 0;
        width: 80px;
        vertical-align: middle;
      }

      &:hover, &:focus, &:active {

      }
    }

    nav {
      display: block;
      margin: 0.5rem 1rem 1rem 1rem;
      // background: var(--color-primary);
      // border: 1px solid #42446E;
      // border: 1px solid rgba(43,45,66,0.3);
      // border-top-left-radius: 10px;
      // border-top-right-radius: 0px;
      // border-bottom-left-radius: 10px;
      // border-bottom-right-radius: 0px;

      ul {
        list-style-type: none;
        padding: 0;
        margin: 0;

        li:first-of-type {
          a {
            // border-top-left-radius: 10px;
            // border-top-right-radius: 0px;
          }
        }

        li:last-of-type {
          a {
            // border-bottom-left-radius: 10px;
            // border-bottom-right-radius: 0px;
          }
        }

        li {
          display: block;
          // margin-bottom: 0.1rem;

          a {
            display: block;
            text-decoration: none;
            color: var(--sidebar-color-default);
            font-size: 1rem;
            font-weight: 400;
            padding: 0.7rem 1rem;
            // border-bottom: 1px solid #D14124;
            // border-radius: 10px;
            // border-bottom: 1px solid rgba(43,45,66,0.3);

            &:hover, &:focus, &:active {
              background: #0F0326;
              color: var(--sidebar-color-default);
            }
          }
        }

      }
    }
  }
}