@import url('https://fonts.googleapis.com/css2?family=Outfit&family=Merriweather:wght@300;400;700&family=Roboto:wght@100;400;700&display=swap');
@import '~bootstrap/scss/bootstrap';
@import "./app";
@import "./sidebar";
@import "./app-navbar";
@import "./app-sidebar";
// #EC0B43
:root {
  --logo-color:#4361ee;
  --logo-font: 'Outfit', sans-serif;
  --logo-font-size: 1.9rem;

  --color-link: #4361ee;
  --color-link-hover: #4361ee;

  --color-sidebar-link-hover: #4361ee;

  //////////
  // #edf2f4;
  --background: #f8f9fa;//#2b2d42;
  --color-default: #343a40;
  

  --color-primary: #4361ee;
  --color-gray: #8d99ae;

  --sidebar-background: #473BF0;
  
  
  --sidebar-width: 300px;
  --sidebar-color-default: #edf2f4;
  //#383C53;//#393B5F; 4a4e69

  --sidebar-footer-background: #0F0326;
}

// :root {
//   // #edf2f4;
//   --background: #f8f9fa;//#2b2d42;
//   --color-default: #1B2021;
//   --color-link: #4366FF;

//   --color-primary: #4361ee;
//   --color-gray: #8d99ae;

//   --sidebar-logo-color:#fff;
//   --sidebar-logo-font: 'Outfit', sans-serif;
//   --sidebar-logo-font-size: 2.5rem;
//   --sidebar-width: 300px;
//   --sidebar-color-default: #edf2f4;
//   --sidebar-background: #473BF0;//#383C53;//#393B5F; 4a4e69

//   --sidebar-footer-background: #0F0326;
// }

$font: 'Roboto', sans-serif;
$font-default-size: 1rem;

body {
  color: var(--color-default);
  font-family: $font;
  font-size: $font-default-size;
  background: var(--background) !important;
}

.toastContainer {
  z-index: 99999;
}
////////////////////
.app-landing-page {
  display: block;
  text-align: center;
   
  a {
    display: inline-block;
    text-align: center;
    color: var(--color-default);
    text-decoration: none;

    &:hover,&:focus {
      text-decoration: underline;
    }
  }
  
  > a {display: block;}

  .title {
    font-size: 3rem;
    font-weight: 700;
    &:hover,&:focus {
      text-decoration: none;
    }
  }

  .links-box {
    margin-top: 3rem;
  }
}

.text-right {
  text-align: right;
}