.link-primary {
  color: var(--color-primary);
}

.underline {
  text-decoration: underline;
}

.app {
  a:not(.btn) {
    color: var(--color-link);
  }

  .app-bar {
    display: flex;
    flex-direction: row;
    padding: 0.5rem 1rem;
    width: 100%;

    .app-bar-main {
      flex-grow: 1;
    }
  }


  .breadcrumb-item {
    color: var(--color-default);
    a {
      color: var(--color-default);
      text-decoration: none;

      &:hover {
        color: var(--color-primary);
      }
    }
    
    &.active {
      color: var(--color-default);
    }
  }
  .breadcrumb-item + .breadcrumb-item::before {
    color: var(--color-default);
  }

  .main {
    padding-left: var(--sidebar-width);
    // padding-top: 1rem;
    // padding-right: 1rem;
    // padding-bottom: 1rem;
  }

  .dropdown.select-network.open {
    position: initial;
    
    .dropdown-menu {
      width: 100%;
    }
  }

  .vaults-list {
    border-radius: 10px;
    background: #000;
  }

  .btn {
    &.btn-primary {
      background-color: var(--color-primary);
      border-color: var(--color-primary);
    }
  }

  .auth-method-box {
    display: block;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    border: 1px solid var(--color-gray);
  }

  .pull-right {
    float: right;
  }

  .organizations-list {
    .list-item {
      display: flex;
      border-left: 1px solid var(--color-gray);
      border-right: 1px solid var(--color-gray);
      border-top: 1px solid var(--color-gray);

      padding: 0.5rem 1rem;
      flex-direction: row;
      align-items: center;

      .item-title {
        flex-grow: 1;

        .title-link {
          color: var(--color-link);
          font-weight: 600;
          text-decoration: none;

          &:hover,&:active,&:focus {
            text-decoration: underline;
          }
        }
      }

      .item-actions {
        justify-content: flex-end;
      }
    }

    .list-item:first-of-type {
      border-top-right-radius: 5px;
      border-top-left-radius: 5px;
      border-bottom: 0;
    }

    .list-item:last-of-type {
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 5px;
      border-bottom: 1px solid var(--color-gray);
    }

    .list-item:first-of-type:last-of-type {
      // border-bottom: 1px;  
    }
  }

  .card {
    &.card-red {
      background: #d90429;
    }
  }
}

.toast:not(.show) {
  display: initial;
}