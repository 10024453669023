.app-main {
  padding-top: calc(44px);
  padding-left: 300px;
  width: 100%
}

.app-sidebar {
  position: fixed;
  z-index: 899;
  height: 100%;
  max-height: 100%;
  background: #fff;
  border-right: 1px solid #e9ecef;
  padding-top: calc(44px + 0.8rem);
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  padding-bottom: 0.8rem;
  width: 300px;
  overflow-y: auto;
  
  a {
    &:hover,&:active {
      color: var(--color-sidebar-link-hover);
      text-decoration: underline;
    }
  }

  strong {color: var(--color-primary)}
}