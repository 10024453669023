.app-subnavbar {
  position: fixed;
  z-index: 999;
  top: 44px;
  left: 0;
  height: 44px;
  width: 100%;
  background: #fff;
  border-bottom: 1px solid #e9ecef;
}

.app-navbar {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  background: #fff;
  padding: 0.3rem 0.4rem;
  height: 44px;
  border-bottom: 1px solid #e9ecef;

  .logo {
    display: inline-block;
    text-align: left;
    text-decoration: none;
    color: var(--logo-color);
    font-family: var(--logo-font);
    font-size: var(--logo-font-size);
    padding: 0;
    font-weight: 700;
    line-height: 1.8rem;
    display: inline-block;
    // line-height: 2.9rem;
    // border-bottom: 2px solid var(--sidebar-logo-color);
    // margin: 0.5rem;
    &:hover, &:focus, &:active {
      // color:#fee440;
    }
  }
}